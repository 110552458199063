<template>
  <NavQuestion
    :heading="heading"
    :number="number"
    :subHeading="subHeading"
    :questionOptions="true"
  >
    <template v-slot:contents>
      <InputOptionSmall
        :letter="'a'"
        :option="'Male'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-male.png'"
        :selected="gender === 'Male'"
        @selected="gender = 'Male'"
      ></InputOptionSmall>
      <InputOptionSmall
        :letter="'b'"
        :option="'Female'"
        :image="'https://mylastwill.s3.amazonaws.com/static/img/button-female.png'"
        :selected="gender === 'Female'"
        @selected="gender = 'Female'"
      ></InputOptionSmall>
    </template>
    <template v-slot:navButtons>
      <NavQuestionButtons
        v-bind:validated="isValidated"
        v-bind:forwardText="'Next'"
        v-bind:forwardLink="forwardTo"
        v-bind:backLink="backTo"
        v-bind:center="false"
      ></NavQuestionButtons>
    </template>
  </NavQuestion>
</template>

<script>
import NavQuestion from '@/common/ui/NavQuestion'
import NavQuestionButtons from '@/common/ui/NavQuestionButtons'
import InputOptionSmall from '@/common/ui/InputOptionSmall'
import { http } from '@/services'

export default {
  name: 'ProfilePartnerGender',
  components: {
    NavQuestion,
    NavQuestionButtons,
    InputOptionSmall
  },
  mounted() {
    this.$emit('progress', '84%')
  },
  computed: {
    background() {
      if (this.start) return 'bg-personal-details-cookie'
      return 'bg-personal-details'
    },
    number() {
      if (
        this.$store.getters.checkoutBasket &&
        this.$store.getters.checkoutBasket.coupon &&
        this.$store.getters.checkoutBasket.coupon.charity
      )
        return '12'
      return '10'
    },
    start() {
      return this.$router.history.current.name === 'WriteMyWillPartnerGender'
    },
    forwardTo() {
      if (this.start) {
        if (
          this.personalDetails.marital_status === 'Separated' ||
          this.personalDetails.partner
        )
          return '/write_my_will/username'
        return '/write_my_will/will_for'
      } else {
        if (
          this.personalDetails.marital_status === 'Separated' ||
          this.personalDetails.partner
        )
          return '/profile/check_and_confirm'
        return '/profile/will_for'
      }
    },
    backTo() {
      if (this.start) return '/write_my_will/partner_name'
      return '/profile/partner_name'
    },
    heading() {
      if (this.personalDetails.marital_status === 'Married')
        return "What is your spouse's gender?"
      if (this.personalDetails.marital_status === 'Separated')
        return "What is you ex partner's gender?"
      return "What is your partner's gender?"
    },
    subHeading() {
      return 'This choice will alter the pronouns used throughout your Will i.e his or her.'
    },
    personalDetails() {
      return this.$store.getters.personalDetails
    },
    user() {
      return this.$store.getters.user
    },
    partner() {
      return this.$store.getters.people.find(
        (person) => person.partner || person.partner_ex
      )
    },
    charityGift() {
      return this.$store.getters.giftCharity.find(
        (gift) => gift.charity_protected
      )
    },
    isValidated() {
      return !!this.gender;
    }
  },
  beforeRouteLeave(to, from, next) {
    if (
      this.personalDetails.partner &&
      this.user &&
      !this.user.broker_created_account
    ) {
      this.$store.commit('personalDetails', { will_for: 'Myself' })
      this.$store.commit('checkoutBasket', { will_for: 'Myself' })
    }
    this.save()
    next()
  },
  watch: {
    partner: {
      immediate: true,
      deep: true,
      handler(value) {
        if (value && value.gender) {
          this.name = value.full_name
          this.gender = value.gender
        } else if (
          this.personalDetails &&
          this.personalDetails.partner_full_name
        ) {
          this.name = this.personalDetails.partner_full_name
          this.gender = this.personalDetails.partner_gender
        }
      }
    }
  },
  data() {
    return {
      gender: ''
    }
  },
  methods: {
    setRelationship() {
      var string = 'partner'
      if (
        this.gender === 'Male' &&
        this.personalDetails.marital_status === 'Married'
      )
        return 'husband'
      else if (
        this.gender === 'Male' &&
        this.personalDetails.marital_status === 'Separated'
      )
        return 'ex partner'
      else if (
        this.gender === 'Female' &&
        this.personalDetails.marital_status === 'Married'
      )
        return 'wife'
      else if (
        this.gender === 'Female' &&
        this.personalDetails.marital_status === 'Separated'
      )
        return 'ex partner'
      return string
    },
    save() {
      this.$store.commit('personalDetails', {
        partner_gender: this.gender
      })
      if (!this.start) {
        if (this.partner && this.gender) {
          var partnerClone = Object.assign({}, this.partner)
          partnerClone.gender = this.gender
          partnerClone.partner =
            this.personalDetails.marital_status !== 'Separated'
          partnerClone.partner_ex =
            this.personalDetails.marital_status === 'Separated'
          partnerClone.relationship = this.setRelationship()
          partnerClone.full_name = this.personalDetails.partner_full_name
          if (partnerClone.partner_ex) {
              partnerClone.distribution_sole_primary = false
          }
          http.patch('/wills/api/person', partnerClone).then((response) => {
            this.$store.commit('personAdd', response.data)
            this.$store.commit('personalDetails', {
              partner_gender: response.data.gender,
              partner_full_name: response.data.full_name
            })
          })
        } else {
          http
            .post('/wills/api/person', {
              full_name: this.personalDetails.partner_full_name,
              gender: this.gender,
              relationship: this.setRelationship(),
              partner: this.personalDetails.marital_status !== 'Separated',
              partner_ex: this.personalDetails.marital_status === 'Separated'
            })
            .then((response) => {
              this.$store.commit('personAdd', response.data)
              console.log(response.data)
              this.$store.commit('personalDetails', {
                partner_gender: response.data.gender,
                partner_full_name: response.data.full_name
              })
            })
        }
      }
    }
  }
}
</script>

<style></style>
